import md5 from 'js-md5';

// 参数排序
function sortByKey(obj) {
    const newkey = Object.keys(obj).sort();
    const newObj = {};
    for (let i = 0; i < newkey.length; i++) {
        newObj[newkey[i]] = obj[newkey[i]];
    }
    return newObj;
}
// 参数序列化
function strJoin(resData) {
    let i = 0;
    let str = '';
    let strJoint = '';
    const arr = Object.keys(resData);
    // for (const key in resData) {
    //     str = `${key}=${resData[key]}`;
    //     i++;
    //     if (i < arr.length) {
    //         strJoint = `${strJoint + str}&`;
    //     } else if (i === arr.length) {
    //         strJoint += str;
    //     }
    // }
    arr.forEach((key) => {
        str = `${key}=${resData[key]}`;
        i++;
        if (i < arr.length) {
            strJoint = `${strJoint + str}&`;
        } else if (i === arr.length) {
            strJoint += str;
        }
    });
    console.log(strJoint);
    return strJoint;
}

// 请求头header配置，data是请求的参数
function getBaseHeaders(data) {
    console.log(data, '---====data=====');
    data.appkey = 'Zm6IcVhASAX0RURZziFZQMj4siTU4fdl';
    const dataSort = sortByKey(data);
    const dataStr = strJoin(dataSort);
    const urlStr = window.location.origin;
    const timestamp = new Date().getTime();
    const nonceStr = Math.random().toString(36).substr(2);
    // 生成前端签名
    const signature = md5(`${dataStr}&Timestamp=${timestamp}&nonce=${nonceStr}&url=${urlStr}`);

    // let postBaseHeaders = {
    //     timestamp: timestamp,
    //     nonce: nonceStr,
    //     signature: signature,
    // };
    return signature;
}

export default getBaseHeaders;
