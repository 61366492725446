<template>
    <div class="coupon_select_container">
        <Action-Sheet
            v-model="visible"
            :round="false">
            <div
                @click.stop=""
                class="content">
                <div class="coupon_title">
                    请选择会员卡
                    <span
                        @click="handleWrapperClick"
                        class="coupon_close" />
                </div>
                <div class="coupon_tabs">
                    <div
                        :class="tabStatus ? 'activated' : 'disabled'"
                        @click="handleClickAvailable"
                        class="tab available">
                        可用({{ availableList.length }})
                    </div>
                    <div
                        :class="tabStatus ? 'disabled' : 'activated'"
                        @click="handleClickInvalid"
                        class="tab invalid">
                        不可用({{ unavailableList.length }})
                    </div>
                </div>
                <div
                    v-if="tabStatus"
                    class="coupon_list_wrapper available_list">
                    <Empty-data
                        v-if="availableList.length === 0"
                        tip="暂无会员卡" />
                    <div
                        v-else
                        class="coupon_list">
                        <coupon-item
                            :couponData="coupon"
                            :key="coupon.id"
                            @toggleCouponItemSelect="handleToggleCouponItemSelect"
                            v-for="coupon in availableList" />
                    </div>
                </div>
                <div
                    v-else
                    class="coupon_list_wrapper invalid_list">
                    <Empty-data
                        v-if="unavailableList.length === 0"
                        tip="暂无会员卡" />
                    <div
                        v-else
                        class="coupon_list">
                        <coupon-item
                            :couponData="coupon"
                            :key="coupon.id"
                            v-for="coupon in unavailableList" />
                    </div>
                </div>
                <div class="footer_btns">
                    <div class="sum_btn">
                        <span class="sum_txt">可抵扣:</span>
                        <span class="money"><span class="rmb">¥</span>{{ totalAmount.toFixed(2) }}</span>
                    </div>
                    <div
                        @click="handleConfirm"
                        class="pay_btn">
                        确认
                    </div>
                </div>
            </div>
        </Action-Sheet>
    </div>
</template>

<script>
import EmptyData from '@/components/empty-data';
import CouponItem from '@/components/coupon-item';
import { ActionSheet } from 'vant';

const ACTIVATED = 1;
const DISABLED = 0;

export default {
    components: {
        EmptyData,
        CouponItem,
        ActionSheet,
    },
    props: {
        availableList: {
            type: Array,
            required: true,
            default: () => [],
        },
        unavailableList: {
            type: Array,
            required: true,
            default: () => [],
        },
        totalAmount: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    data() {
        return {
            visible: false,
            tabStatus: ACTIVATED,
        };
    },
    watch: {
        availableList(newV, oldV) {
            console.log('watch', newV, oldV);
        },
    },
    beforeUpdate() {
        console.log('beforeUpdate');
    },
    methods: {
        handleConfirm() {
            // this.toggleVisible();
            this.$emit('handleCouponConfirm');
        },
        // 切换会员卡选择状态
        handleToggleCouponItemSelect({ id }) {
            this.$emit('toggleCouponItemSelect2', id);
        },
        // 点击可用tab触发
        handleClickAvailable() {
            this.tabStatus = ACTIVATED;
        },
        // 点击不可用tab触发
        handleClickInvalid() {
            this.tabStatus = DISABLED;
        },
        // 切换显隐, 提供给外部组件调用
        toggleVisible() {
            console.log('toggleVisible');
            this.visible = !this.visible;
            console.log('this.visible', this.visible);
        },
        // 点击蒙版效果
        handleWrapperClick() {
            this.toggleVisible();
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    position: relative;
    .coupon_title {
        padding-top: 16px;
        position: relative;
        width: 100%;
        height: 25px;
        line-height: 25px;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        color: #000;
        .coupon_close {
            position: absolute;
            top: 16px;
            right: 20px;
            display: inline-block;
            background-image: url('./close.png');
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
        }
    }
    .coupon_tabs {
        margin-top: 28px;
        width: 100%;
        height: 30px;
        display: flex;
        box-shadow: 0 4px 8px -4px rgba(60, 107, 240, 0.1);
        .tab {
            flex: 1;
            text-align: center;
            height: 100%;
            line-height: 20px;
            font-size: 14px;
            font-weight: 500;
            &.activated {
                position: relative;
                color: #4280f8;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 28px;
                    height: 3px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                    background: #4280f8;
                }
            }
            &.disabled {
                color: #787983;
            }
        }
    }
    .coupon_list_wrapper {
        height: calc(100% - 147px);
        /deep/ .empty {
            margin-top: 40px !important;
        }
        .coupon_list {
            width: 100%;
            height: 100%;
            padding: 10px 18px 48px 18px;
            overflow-y: scroll;
            overflow-x: hidden;
            box-sizing: border-box;
            background: #f5f7fa;
            -webkit-overflow-scrolling: touch;
        }
    }
    .footer_btns {
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 48px;
        line-height: 48px;
        box-shadow: 0 2px 8px 0 rgba(68, 134, 251, 0.12);
        .sum_btn {
            background: #fff;
            flex: 1;
            padding-left: 18px;
            font-size: 20px;
            font-weight: 500;
            color: #fa3e3e;
            .sum_txt {
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #62636c;
            }
            .icon_money {
                font-size: 14px;
            }
            .money {
                font-size: 20px;
                font-weight: 500;
                color: #fa3e3e;
                .rmb {
                    font-size: 14px;
                }
            }
            .origin_price {
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.35);
                margin-left: 15px;
                text-decoration: line-through;
            }
        }
        .pay_btn {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            background: #f36218;
            width: 141px;
        }
    }
}
// }
</style>
