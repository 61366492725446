import api from '@/api';
import { mapState, mapMutations } from 'vuex';
import { subtract } from '@/utils/calculate';
import CouponSelect from '@/components/coupon-select';

const couponMixin = {
    data() {
        return {
            displayExpressFee: false,
            totalAmount: undefined,
            availableList: [],
            unavailableList: [],
            hasSelectedCardAmount: 0,
            tempDeductRel: [],
            tempArr: [],
            userSelect: [],
            deductRelList: [],
        };
    },
    components: {
        CouponSelect,
    },
    computed: {
        ...mapState({
            isCustom: (store) => store.coupon.isCustom,
            cardIds: (store) => store.coupon.cardIds,
            // drugStoreCode: (store) => store.merchantInfo.merchantObj.drugstoreCode,
            couponCellStatus: (store) => store.coupon.couponCellStatus,
            defaultDeductAmount: (store) => store.coupon.defaultDeductAmount,
            relation: (store) => store.coupon.relation,
        }),
        actualPrice() {
            if (this.isMember === '1') {
                // 是会员
                return Number(subtract(
                    this.allMemberPrice,
                    this.hasSelectedCardAmount,
                )).toFixed(2);
            }
            // 不是会员
            return Number(subtract(this.allSum, this.hasSelectedCardAmount)).toFixed(2);
        },
    },
    beforeRouteLeave(to, from, next) {
        console.log('to', to);
        const { path } = to;
        // 如果是 地址页面 或者是 处方页面  那么不清除vuex中的会员卡信息
        if (path !== '/add-address' && path !== '/prescription-info/pay') {
            console.log('456');
            this.clearCustomCouponSelect();
        }
        next();
    },
    methods: {
        ...mapMutations('coupon', ['toggleIsCustom']),
        ...mapMutations('coupon', ['setSelectCardIds']),
        ...mapMutations('coupon', ['clearCustomCouponSelect']),
        ...mapMutations('coupon', ['toggleCouponCellStatus']),
        ...mapMutations('coupon', ['setDefaultDeductAmount']),
        ...mapMutations('coupon', ['setRelation']),
        // coupon select 选择了确认按钮
        handleCouponConfirm() {
            // 隐藏
            this.$refs.refCouponSelect.toggleVisible();

            // 设置选中的卡id到vuex
            this.setSelectCardIds(this.tempArr);

            // 更新抵扣关系
            this.deductRelList = this.tempDeductRel;

            this.setRelation(this.tempDeductRel);

            if (this.tempArr.length === 0) {
                this.toggleCouponCellStatus(2);
            } else {
                this.toggleCouponCellStatus(1);
            }

            if (this.tempArr.length === 0 && this.availableList.length === 0) {
                this.toggleCouponCellStatus(0);
            }

            // 更新抵扣金额
            this.hasSelectedCardAmount = this.totalAmount;
        },

        constructGoodsParams() {
            const selectedGoods = [];
            this.detailData.product.forEach((item) => {
                selectedGoods.push({ goodId: item.goodsId, num: item.count });
            });
            return selectedGoods;
        },

        async userUpdateCouponSelect(selectedCardIds) {
            const selectedGoods = this.constructGoodsParams();

            const result = await api.selectCouponItem({
                selectedGoods,
                pharmacyCode: this.drugStoreCode,
                selectedCardIds,
            });

            return result;
        },

        // item点击了
        handleToggleCouponItemSelect(id) {
            this.toggleIsCustom(true);

            // 找到item  更改选中状态
            this.availableList.forEach((coupon, index) => {
                if (coupon.id === id) {
                    this.$set(this.availableList, index, {
                        ...coupon,
                        check: !coupon.check,
                    });
                }
            });

            // 发送请求
            let selectedCardIds = [];
            selectedCardIds = this.availableList.filter(
                (item) => item.check === true,
            );

            const arr = selectedCardIds.map((item) => item.id);
            const uniqueArr = new Set(arr);
            const selectedCards = Array.from(uniqueArr);

            // 更新vuex标识数据
            // 请求新的会员卡扣减规则
            this.userSelect = selectedCards;
            this.userUpdateCouponSelect(selectedCards).then((res) => {
                this.processCoupon(res);
            });
        },

        // 点击了会员卡 cell
        handleCouponCellClick() {
            this.$refs.refCouponSelect.toggleVisible();
            this.userUpdateCouponSelect(this.cardIds).then((res) => {
                this.processCoupon(res);
            });
        },

        // 获取会员卡列表
        getCouponList() {
            // 构造请求参数
            const selectedGoods = this.constructGoodsParams();

            // 请求会员卡列表
            api.postCouponList({
                selectedGoods,
                pharmacyCode: this.drugStoreCode,
            }).then((res) => {
                if (res.data.availableList.length === 0) {
                    // 没有可用会员卡列表 标识`暂无可用`
                    this.toggleCouponCellStatus(0);
                } else {
                    // 有可用会员卡
                    this.toggleCouponCellStatus(1);
                }

                // 记录默认会员卡ids
                const arr = res.data.deductRelList.map((deduct) => deduct.cardId);
                const uniqueArr = Array.from(new Set(arr));

                // 选中的卡列表, 记录到vuex
                this.setSelectCardIds(uniqueArr);

                // 会员卡抵扣金额
                this.hasSelectedCardAmount = res.data.totalAmount;

                // 设置默认可用额
                this.setDefaultDeductAmount(res.data.totalAmount);

                // 会员卡抵扣关系表
                this.deductRelList = res.data.deductRelList;

                this.setRelation(res.data.deductRelList);
            });
        },

        getDefaultAmount() {
            const selectedGoods = this.constructGoodsParams();
            api.postCouponList({
                selectedGoods,
                pharmacyCode: this.drugStoreCode,
            }).then((res) => {
                this.setDefaultDeductAmount(res.data.totalAmount);
            });
        },

        // 处理会员卡数据
        processCoupon(res, updateCellInfo) {
            // 可用卡
            this.availableList = res.data.availableList
                ? res.data.availableList.map((item) => ({
                    ...item,
                    selected: true,
                }))
                : [];

            if (updateCellInfo && this.availableList.length === 0) {
                // 知道了过来的， 并且可用列表为空
                this.toggleCouponCellStatus(0);
                // this.clearCustomCouponSelect();
                this.setRelation([]);
            }

            if (updateCellInfo && (res.data.deductRelList && res.data.deductRelList.length === 0) && this.availableList.length !== 0) {
                // 没有抵扣  但是有可用
                this.hasSelectedCardAmount = 0;
                this.toggleCouponCellStatus(2);
                this.getDefaultAmount();
                this.setRelation([]);
            }

            if (updateCellInfo && this.availableList.length !== 0 && res.data.deductRelList.length !== 0) {
                // 该卡的扣减金额发生了变化
                this.hasSelectedCardAmount = res.data.totalAmount;
                this.toggleCouponCellStatus(1);
                this.getDefaultAmount();
                this.setRelation(res.data.deductRelList);
            }

            // 不可用列表 unavailableList
            this.unavailableList = res.data.unavailableList
                ? res.data.unavailableList.map((item) => ({
                    ...item,
                    displayReason: true,
                    status: 'invalid',
                    hiddenTag: true,
                }))
                : [];

            // 可用总金额 totalAmount
            this.totalAmount = res.data.totalAmount;

            // 临时保存抵扣关系
            this.tempDeductRel = res.data.deductRelList;

            // 构造唯一默认选中的卡id数组
            // 数组去重
            const arr = res.data.deductRelList
                ? res.data.deductRelList.map((deduct) => deduct.cardId)
                : [];
            const uniqueArr = Array.from(new Set(arr));

            // 临时保存
            this.tempArr = uniqueArr;

            // 双循环 构造选中效果 不让用for of循环 (eslint)
            const arr2 = [...new Set(uniqueArr.concat(this.userSelect))];
            arr2.forEach((item) => {
                this.availableList.forEach((coupon) => {
                    if (coupon.id === item) {
                        coupon.check = true;
                    }
                });
            });
            this.userSelect = [];
        },
    },
};

export default couponMixin;
